import { Button } from "@mui/material";
import { ErrorPumpkin } from "Asset/Animations";
import React from "react";
import { ORIGIN } from "../Config/url_api";
import { postData } from "../Helpers/tools";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hashError: false,
            error: null
        }
    }
    // state = {hasError: false, error: null}

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {
            hashError: true,
            error
        };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);
        postData(ORIGIN + '/logerror/frontend', {
            fullPath: window.location.hash,
            error: {
                message: error.message,
                stack: error.stack,
            },
            errorInfo,
        });
    }

    render() {
        if (this.state.hashError) {
            return (
                <div style={{height: 'calc(100vh - 218px)', display: 'flex', textAlign: 'center', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <span style={{fontSize: '2rem', fontWeight: 800, color: "#d32f2f"}}>ERROR!!!</span>
                    <ErrorPumpkin
                     style={{
                        width: "100%",
                        maxWidth: 300
                     }}
                    />
                    <label style={{fontSize: '1.25rem', fontWeight: 600, margin: "16px 0px"}}>Please refresh page, or contact IT</label>
                    <Button onClick={() => {window.location.reload()}} variant="contained" color="error">Refresh Page</Button>
                </div>
            );
        };

        return this.props.children;
    }

}

export default ErrorBoundary;